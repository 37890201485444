import Send from '../../cp.client.js'

export default {
    cpScenarioList (param) {
        return Send({
            url: '/cp/scenario/list',
            method: 'get',
            params: {
                scenarioNm: param.scenarioNm,
                createUser: param.createUser,
                effectFr: param.effectFr,
                effectTo: param.effectTo,
                category: param.category,
                effectYn: param.effectYn
            }
        })
    },
    scenarioNmVali (param) {
      return Send({
        url: '/cp/scenario/scenarioNmVali',
        method: 'get',
        params: {
          scenarioNm: param
        }
      })
    },
    saveCpScenario (data) {
        return Send({
            url: '/cp/scenario/save',
            method: 'put',
            data: data
        })
    },
    apiCall () {
        return Send({
            url: '/cp/api/call-action-handler',
            method: 'get',
            params: {
                actionCd: 'B101'
            }
        })
    }
}
