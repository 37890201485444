import Send from '../../cp.client.js'

export default {
    factorList (param) {
        return Send({
            url: '/cp/factor/list',
            method: 'get'
        })
    }
}
